import { GetColumnGroups, GetStatFormInputs, GetStatTabProps } from '../../Services/ApiSvc';

const columnManager = (function () {
    function genericFormatter(decimals = 0, withComma = false, withPercent = false, currency = false, multiLine = false) {
        return function (cell, formatterParams, onRendered) {
            let val = cell.getValue();
            if (val == null || (!multiLine && isNaN(val))) {
                return "--";
            }

            if (multiLine && isNaN(val)) {
                cell.getElement().style.whiteSpace = 'pre-wrap';
                return val
                    .split('\n')
                    .map(formatVal)
                    .join('\n')
            } else {
                return formatVal(val);
            }

            function formatVal(val) {
                val = Number(val).toFixed(decimals);
                if (withComma) {
                    val = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                if (withPercent) {
                    val = val + "%";
                }
                if (currency) {
                    if (Number(cell.getValue()) < 0) {
                        return 'N/A';
                    }
                    val = "$" + val;
                }
                if (decimals > 2 && !withPercent && String(val).indexOf('0.') === 0) {
                    val = String(val.substring(1));
                }
                return val;
            }
        }
    };

    function awardsFormatter(cell, formatterParams, onRendered) {
        let val = cell.getValue();
        if (val) {
            let splitAward = val.split(', ');
            let awards = [];
            splitAward.forEach(award => {
                if (shouldBold(award)) {
                    awards.push('<strong>' + award + '</strong>');
                }
                else {
                    awards.push(award);
                }
            })
            val = awards.join(', ');
            if (formatterParams.clickStyling) {
                cell.getElement().style.cursor = 'pointer';
                cell.getElement().style.border = '2px outset black';
            }
        }
        return val;

        function shouldBold(award) {
            let noPosAwards = ['AS', 'ASMVP', 'CPOY', 'CY-1', 'FBA-1', 'FOY', 'Hoffman-1', 'LCSMVP', 'MVP-1', 'Rivera-1',
                'ROY-1', 'RRM-1', 'SNPOY', 'WSMVP'];
            let posAwardRegex = /(GG-1\()|(All-MLB TM 1)|(ASE)|(SS\()/;

            return noPosAwards.includes(award) || posAwardRegex.test(award)
        }
    };

    function baserunnersFormatter(cell, formatterParams, onRendered) {
        let val = cell.getValue().toString();
        let formattedVal = '';
        for (let base = 1; base <= 3; base++) {
            formattedVal = addBase(formattedVal, base.toString(), val)
        }
        return formattedVal;

        function addBase(bases, base, val) {
            if (val.includes(base))
                return bases.concat(base);
            else
                return bases.concat('-');
        }
    }

    function injuryDiagnosisFormatter(cell, formatterParams, onRendered) {
        cell.getElement().style.whiteSpace = 'pre-wrap'
        if (cell.getValue() === 'Unknown') {
            return '';
        }
        return cell.getValue();
    };

    function injuryRatingFormatter(cell, formatterParams, onRendered) {
        cell.getElement().style.whiteSpace = 'pre-wrap'
        if (cell.getValue() === 0) {
            return 'Injury Incident Not Observed';
        }
        return cell.getValue();
    };

    function runDiffFormatter(cell, formatterParams, onRendered) {
        let val = cell.getValue().toString();
        switch (val) {
            case '4':
                return '>=4';
            case '-4':
                return '<=-4';
            default:
                return val;
        }
    }

    function genericSorter(a, b, aRow, bRow, column, dir, sorterParams) {
        var aData = aRow.getData();
        var bData = bRow.getData();
        if ((aData.Player === 'Career Totals' && dir === 'desc') || (bData.Player === 'Career Totals' && dir === 'asc')) return -1;
        else if ((aData.Player === 'Career Totals' && dir === 'asc') || (bData.Player === 'Career Totals' && dir === 'desc')) return 1;
        else if (!isNaN(a) && !isNaN(b)) {
            return a - b;
        }
        else if (a == null) return 1;
        else if (b == null) return -1;
        else {
            if (a.toLowerCase() < b.toLowerCase()) return -1
            if (a.toLowerCase() > b.toLowerCase()) return 1
            return 0
        }
    };

    function toggleClass(col, className) {
        let cssClasses = col.cssClass.split(' ');
        const breakIndex = cssClasses.findIndex((e) => e === className);
        if (~breakIndex) {
            cssClasses.push(className);
        } else {
            cssClasses.splice(breakIndex, 1);
        }
        return cssClasses.join(' ');
    }

    function genericTitleFormatter(wrapText) {
        return function (cell, formatterParams, onRendered) {
            if (wrapText) {
                cell.getElement().style.whiteSpace = "pre-wrap";
            }
            return cell.getValue();
        }
    };

    function createColumn(columnProps, formInputs) {
        const column = {
            id: columnProps.statId,
            title: columnProps.title,
            field: columnProps.field,
            width: columnProps.width,
            headerSort: columnProps.useSortFunc,
            headerSortStartingDir: columnProps.sortDir,
            sorter: columnProps.useSortFunc ? genericSorter : null,
            titleFormatter: genericTitleFormatter(columnProps.wrapText),
            cssClass: '',
            statPosType: columnProps.statPosType,
            formInputs: Object.assign(formInputs ?? {}, { field: columnProps?.field }),
            decimals: columnProps.decimals,
            withComma: columnProps.withComma,
            withPercent: columnProps.withPercent,
            exportFormat: columnProps.exportFormat,
            resizable: false,
        }

        function addCssClass(css) {
            if (column.cssClass !== '') {
                column.cssClass += ` ${css}`;
            } else {
                column.cssClass = css;
            }
        }

        switch (columnProps.formatterFunc) {
            case 'Generic':
                column.formatter =
                    genericFormatter(
                        columnProps.decimals,
                        columnProps.withComma,
                        columnProps.withPercent,
                        columnProps.asCurrency,
                        columnProps.wrapText
                    );
                break;
            case 'Awards':
                column.formatter = awardsFormatter;
                break;
            case 'Baserunners':
                column.formatter = baserunnersFormatter;
                break;
            case 'InjuryRating':
                column.formatter = injuryRatingFormatter;
                break;
            case 'InjuryDiag':
                column.formatter = injuryDiagnosisFormatter;
                break;
            case 'DateTime':
                column.formatter = 'datetime';
                column.formatterParams = {
                    inputFormat: 'iso',
                    outputFormat: 'M/d/yyyy',
                    invalidPlaceholder: '--',
                };
                break;
            case 'RowNum':
                column.formatter = function (cell, formatterParams, onRendered) {
                    var content = document.createElement("span");
                    var row = cell.getRow();

                    function updateRowNumber() {
                        let paginationSize = cell.getTable().getPageSize();
                        let pageIndex = cell.getTable().getPage() - 1;
                        let pageInitRowNumber = pageIndex * paginationSize;
                        content.innerText = row.getPosition(true) + pageInitRowNumber;
                    }

                    cell.getTable().on("renderComplete", function () {
                        updateRowNumber();
                    });

                    cell.getTable().on("headerClick", function () {
                        updateRowNumber();
                    });

                    cell.getTable().on("pageLoaded", function () {
                        updateRowNumber();
                    });

                    return content;
                };
                break;
            case 'RunDiff':
                column.formatter = runDiffFormatter;
                break;
            case 'TickCross':
                column.formatter = 'tickCross';
                column.formatterParams = {
                    allowEmpty: true,
                    allowTruthy: false,
                    tickElement: "<img src='./images/Check.png' style='margin-right: auto; margin-left: auto; display: block;'/>",
                    crossElement: "<img src='./images/NoCheck.png' style='margin-right: auto; margin-left: auto; display: block;'/>",
                };
                break;
            case 'textarea':
                column.formatter = 'textarea';
                break;
            default:
                if (columnProps.wrapText)
                    column.formatter = 'textarea';

                break;
        }

        // Don't need right because default is right align
        switch (columnProps.align) {
            case 'left':
                column.hozAlign = 'left';
                addCssClass('leftAlign');
                break;
            case 'center':
                column.hozAlign = 'center';
                addCssClass('centerAlign');
                break;
        }

        column.toggleBreak = function toggleBreak(addBreak) {
            if ((!addBreak && !column.cssClass.includes('break'))
                || (addBreak && column.cssClass.includes('break'))) {
                return column;
            }
            if (addBreak) {
                addCssClass('break');
            }
            else {
                column.cssClass = column.cssClass.replace('break', '').trim();
            }
            return column;
        }

        if (columnProps.children) {
            column.columns = [];
            columnProps.children.forEach(childColumn => {
                column.columns.push(createColumn(childColumn));
            })
        }

        if (columnProps.break) {
            addCssClass('break');
        }

        if (columnProps.singleRow) {
            addCssClass('single-row-header');
        }

        return column;
    }

    
    let columns = [];
    let colGroups = [];
    let statTabProps = [];
    let statFormInputs = [];

    async function populateColumns() {
        if (columns.length > 0) {
            return;
        }
        [statTabProps, statFormInputs] = await Promise.all([GetStatTabProps(), GetStatFormInputs()])
        if (columns.length > 0) {
            return;
        }
        statTabProps.forEach((statColumn) => {
            columns.push(createColumn(statColumn, statFormInputs.find(sfi => sfi.statId === statColumn.statId)));
        })
    }

    async function populateColGroups() {
        if (colGroups.length > 0) {
            return;
        }
        let apiColGroups = await GetColumnGroups();
        if (colGroups.length > 0) {
            return;
        }
        apiColGroups.forEach((colGroup) => {
            colGroups.push(colGroup);
        })
    }

    

    async function getStatTabPropsById(id) {
        await populateColumns();

        return Object.assign({}, statTabProps.find(statTabProp => statTabProp.statId === id));
    }

    async function getAllColumns() {
        await populateColumns();

        let retColumns = [];
        columns.forEach(column => {
            retColumns.push(Object.assign({}, column));
        })
        return retColumns;
    }

    async function getAllGroups() {
        await populateColGroups();
        return colGroups;
    }

    async function getAllGroupsBySection(section) {
        await populateColGroups();
        //let retGroups = colGroups.filter(group => ['All', section].includes(group.section));
        //Once Rank Report is pointed to query tool, uncomment out the above line, and remove the section between the two dividers:
        //==================================================================================================================================
        let retGroups = [];
        if (section === 'RankReport') {
            retGroups = colGroups.filter(group => [10,11,12,13,14,15,16,17,18,19,20,21,22,28,29,30,31,37,39].includes(group.id));
        }
        else {
            retGroups = colGroups.filter(group => ['All', section].includes(group.section));
        }
        //==================================================================================================================================
        return await Promise.all(retGroups.map(async (curGroup) => {
            let returnGroup = Object.assign({}, curGroup);
            returnGroup.columns = [];
            for (const colId of curGroup.colIds) {
                let retCol = await getSingleColumnById(colId);
                returnGroup.columns.push(retCol);
            }
            returnGroup.colIds = curGroup.colIds.map(colId => {
                return colId > 10000 ? colId - 10000 : colId;
            });
            return returnGroup;
        }));
    }

    async function getSingleColumnById(id) {
        await populateColumns();

        let curColumn = {};
        Object.assign(
            curColumn,
            columns.find(column => column.id === (id > 10000 ? id - 10000 : id))
        );
        if (id > 10000) {
            curColumn.break = true;
            curColumn.toggleBreak(true);
        }
        return curColumn;
    }

    /**
     * Returns the columns based on the array of ids passed in
     * @param {[int]} ids
     */
    async function getColumnsById(ids) {
        await populateColumns();

        let retColumns = []
        for (const id of ids) {
            let column = await getSingleColumnById(id);
            retColumns.push(column);
        }
        return retColumns;
    }

    /**
     * Returns the column based on a given groupId
     * @param {number} groupId id of the group to return columns from
     */
    async function getColumnsByGroup(groupId) {
        await populateColumns();

        let retColumns = [];
        if (groupId > 9 || groupId < 5 || groupId === 8) {
            let groupColIds = await getColIdsFromGroup(groupId);
            for (const colId of groupColIds) {
                let retCol = await getSingleColumnById(colId);
                retColumns.push(retCol);
            }
            return retColumns;
        }
        else {
            let groupCols;
            switch (groupId) {
                case 5:
                    groupCols = batterStatcast;
                    break;
                case 6:
                    groupCols = pitcherStatcast;
                    break;
                case 7:
                    groupCols = catcherStatcast;
                    break;
                case 9:
                    groupCols = pitcherCombStatcast;
                    break;
                default:
                    break;
            }

            for (const groupCol of groupCols) {
                let retColumn = {};
                if (groupCol.id > 0) {
                    let curStatTabProps = await getStatTabPropsById(groupCol.id);
                    if (groupCol.break) {
                        curStatTabProps.break = true;
                    }
                    curStatTabProps.singleRow = true;
                    retColumn = createColumn(curStatTabProps);
                }
                else {
                    retColumn.title = groupCol.title;
                    retColumn.width = 0;
                    retColumn.columns = [];
                    for (const nestedCol of groupCol.children) {
                        let curStatTabProps = await getStatTabPropsById(nestedCol.id);
                        if (nestedCol.break) {
                            curStatTabProps.break = true;
                        }
                        let retNestedColumn = createColumn(curStatTabProps);
                        retColumn.width += retNestedColumn.width;
                        retNestedColumn.title = nestedCol.title || retNestedColumn.title;
                        retColumn.columns.push(retNestedColumn);
                    }
                }
                retColumn.title = groupCol.title || retColumn.title;
                retColumns.push(retColumn);
            }
            return retColumns;
            //return leaderboardGroups[groupId - 5];
        }
    }

    /**
     * Returns array of groups that holds information about the group as well as the non "break" column ids
     */
    async function getColumnGroups() {
        await populateColGroups();
        return colGroups.map(group => {
            return group.colIds.map(colId => {
                return colId > 10000 ? colId - 10000 : colId;
            });
        });
    }

    /**
    * Returns an array of column ids from selected group
    * @param {number} groupId groupId of group to select column ids from
    */
    async function getColIdsFromGroup(groupId) {
        await populateColGroups();
        return colGroups.find(group => group.id === groupId).colIds;
    }

    let batterStatcast = [
        { id: 1 },
        { id: 2 },
        { id: 3 },
        { id: 4, break: true },
        { id: 5 },
        { id: 6, break: true },
        { id: 8 },
        { id: 10 },
        { id: 11 },
        { id: 12, break: true },
        {
            id: -1, title: 'Exit Velocity(MPH)', children: [
                { id: 14, title: 'Max' },
                { id: 15, title: 'AVG' },
                { id: 16, title: 'FB/LD' },
                { id: 17, title: 'GB', break: true },
            ]
        },
        {
            id: -1, title: 'Distance (ft)', children: [
                { id: 19, title: 'Max' },
                { id: 20, title: 'AVG' },
                { id: 21, title: 'Avg HR', break: true },
            ]
        },
        {
            id: -1, title: 'Hard Hit', children: [
                { id: 22 },
                { id: 23, title: '%', break: true },
            ]
        },
        {
            id: -1, title: 'Barrels', children: [
                { id: 24, title: '#' },
                { id: 25 },
                { id: 26, break: true },
            ]
        },
        { id: 27 },
        { id: 28, break: true },
        {
            id: -1, title: 'Expected Batting', children: [
                { id: 31 },
                { id: 32 },
                { id: 33 },
                { id: 34, break: true },
            ]
        },
        {
            id: -1, title: 'Running', children: [
                { id: 75 },
                { id: 76, break: true },
            ]
        },
        { id: 78, break: true },
        { id: 73 },
        { id: 74 },
    ]

    let pitcherStatcast = [
        { id: 1 },
        { id: 2 },
        { id: 3 },
        { id: 4, break: true },
        { id: 5 },
        { id: 6, break: true },
        { id: 7 },
        { id: 144 },
        { id: 310 },
        { id: 141 },
        { id: 142 },
        { id: 143, break: true },
        {
            id: -1, title: 'Exit Velocity(MPH)', children: [
                { id: 137, title: 'Max' },
                { id: 138, title: 'AVG' },
                { id: 139, title: 'FB/LD' },
                { id: 140, title: 'GB', break: true },
            ]
        },
        {
            id: -1, title: 'Hard Hit', children: [
                { id: 130 },
                { id: 131, title: '%', break: true },
            ]
        },
        {
            id: -1, title: 'Barrels', children: [
                { id: 132, title: '#' },
                { id: 133 },
                { id: 134, break: true },
            ]
        },
        { id: 135 },
        { id: 136 },
        { id: 243, break: true },
        {
            id: -1, title: 'Expected Batting', children: [
                { id: 126 },
                { id: 127 },
                { id: 128 },
                { id: 129, break: true },
            ]
        },
        {
            id: -1, title: 'Average Spin Rate(RPM)', children: [
                { id: 330, title: 'FB' },
                { id: 338, title: 'SI' },
                { id: 346, title: 'SL' },
                { id: 370, title: 'CH' },
                { id: 362, title: 'CB', break: true },
            ]
        },
        {
            id: -1, title: 'Average Velocity(MPH)', children: [
                { id: 329, title: 'FB' },
                { id: 337, title: 'SI' },
                { id: 345, title: 'SL' },
                { id: 369, title: 'CH' },
                { id: 361, title: 'CB', break: true },
            ]
        },
        { id: 73 },
        { id: 74 },
    ]

    let catcherStatcast = [
        { id: 1 },
        { id: 2 },
        { id: 3 },
        { id: 4, break: true },
        { id: 5 },
        { id: 6, break: true },
        { id: 120 },
        { id: 121 },
        { id: 119, break: true },
        {
            id: -1, title: 'Throwing', children: [
                { id: 106, title: 'Arm' },
                { id: 107, title: 'Exchange', break: true },
            ]
        },
        {
            id: -1, title: 'Pop Time 2B(AVG)', children: [
                { id: 108, title: 'Att.' },
                { id: 109, title: 'All' },
                { id: 110, title: 'SB' },
                { id: 111, title: 'CS', break: true },
            ]
        },
        {
            id: -1, title: 'Pop Time 3B(AVG)', children: [
                { id: 112, title: 'Att.' },
                { id: 113, title: 'All' },
                { id: 114, title: 'SB' },
                { id: 115, title: 'CS', break: true },
            ]
        },
        {
            id: -1, title: 'Framing', children: [
                { id: 116, title: 'Pitches' },
                { id: 117, title: 'Runs Extra Strikes' },
                { id: 118, title: 'Strike Rate', break: true },
            ]
        },
        { id: 72, break: true },
        { id: 73 },
        { id: 74 },
    ]

    let pitcherCombStatcast = [
        { id: 1 },
        { id: 2 },
        { id: 3 },
        { id: 4, break: true },
        { id: 5 },
        { id: 6, break: true },
        { id: 228 },
        { id: 229 },
        { id: 47, break: true },
        { id: 51 },
        { id: 52 },
        { id: 53, break: true },
        { id: 135 },
        { id: 136 },
        { id: 240 },
        { id: 243, break: true },
        { id: 7 },
        { id: 141 },
        { id: 142 },
        { id: 138, title: 'ExVelo' },
        { id: 131, title: 'Hard%' },
        { id: 132, break: true },
        { id: 128 },
        { id: 129, break: true },
        {
            id: -1, title: 'Average Spin Rate(RPM)', children: [
                { id: 330, title: 'FB' },
                { id: 338, title: 'SI' },
                { id: 346, title: 'SL' },
                { id: 370, title: 'CH' },
                { id: 362, title: 'CB', break: true },
            ]
        },
        {
            id: -1, title: 'Average Velocity(MPH)', children: [
                { id: 329, title: 'FB' },
                { id: 337, title: 'SI' },
                { id: 345, title: 'SL' },
                { id: 369, title: 'CH' },
                { id: 361, title: 'CB', break: true },
            ]
        },
        { id: 68, break: true },
        { id: 780 },
        { id: 62 },
        { id: 63, break: true },
        { id: 72, break: true },
        { id: 73 },
        { id: 74 },
    ]

    populateColumns();
    populateColGroups();
    return {
        getAllColumns,
        getAllGroups,
        getAllGroupsBySection,
        getSingleColumnById,
        getColumnsById,
        getColumnsByGroup,
        getColumnGroups,
        getColIdsFromGroup,
    };
})();

export { columnManager };
