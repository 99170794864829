import React, { useEffect, useState } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import { GetArbitrators, GetPlayers, GetReportArbitration } from '../../Services/ApiSvc';
import { columnManager } from '../Common/ColumnManager';
import ExcelExporter from '../Common/ExcelExporter';
import PDFExporter from '../Common/PDFExporter';
import StatFilter, { addFilterAsParameter, convertFilterToNote, createFilter } from '../Common/StatFilter';
import TabulatorTable from '../Common/TabulatorTable';

function ArbitrationReport() {
    let curSeason = new Date().getFullYear();

    const [allPlayers, setAllPlayers] = useState([]);
    const [arbitrators, setArbitrators] = useState([]);
    const [columns, setColumns] = useState(null);
    const [data, setData] = useState(null);
    const [excelNotes, setExcelNotes] = useState(null);
    const [filters, setFilters] = useState([]);
    const [endSeason, setEndSeason] = useState(curSeason);
    const [startSeason, setStartSeason] = useState(curSeason);
    const [reportType, setReportType] = useState('all');

    useEffect(() => {
        (async function Initialize() {
            let allPlayersFromAPI = await GetPlayers();
            let arbitratorsFromAPI = await GetArbitrators();
            let colGroups = await columnManager.getAllGroupsBySection('ArbitrationReport');
            let arbFilters = [];

            colGroups.find(group => group.label === 'ArbitrationFilters').columns
                .forEach((column, filterIndex) => {
                    let filter = Object.assign({}, createFilter(filterIndex, column));

                    if (column.id === 395) {
                        filter.people = allPlayersFromAPI;
                    } else if (column.id === 802) {
                        filter.people = arbitratorsFromAPI;
                    }

                    arbFilters.push(filter);
                });
            let arbColumns = colGroups.find(group => group.label === 'ArbitrationReport').columns;

            setAllPlayers(allPlayersFromAPI);
            setArbitrators(arbitratorsFromAPI);
            setColumns(arbColumns);
            setFilters(arbFilters);
        })()
    }, []);

    async function getData() {
        setData({ loading: true, rows: null })
        let seasons = Array.from(
            new Array(endSeason - startSeason + 1),
            (season, seasonIndex) => seasonIndex + Number(startSeason)
        );
        let filterObj = filters.reduce(addFilterAsParameter, { seasons: seasons });
        let res = await GetReportArbitration(filterObj);
        updateNotes(filters);
        setData(Object.assign({ loading: false }, groupData(res)));
    }

    function groupData(arbData) {
        let aggDataCols = ['ArbCaseResult', 'aWAR', 'ArbPlayerSubmission', 'ArbMidPoint', 'ArbTeamSubmission', 'ArbSpread', 'ArbFinalAmount', 'MoneyPeraWAR']

        let totalGroupingDataAgg = {};
        arbData.slice(0)
            .map(arbCase => {
                arbCase.totalGroup = 'totalGroup';
                return arbCase;
            })
            .forEach(arbCase => groupDataByCol('totalGroup', totalGroupingDataAgg, arbCase));

        aggDataCols.forEach(aggDataCol => {
            totalGroupingDataAgg.totalGroup[aggDataCol] = formatAggData(aggDataCol, 'Player Win', totalGroupingDataAgg.totalGroup)
                + formatAggData(aggDataCol, 'Team Win', totalGroupingDataAgg.totalGroup)
                + formatAggData(aggDataCol, 'Settled (1yr)', totalGroupingDataAgg.totalGroup)
                + formatAggData(aggDataCol, 'total', totalGroupingDataAgg.totalGroup)
                + formatAggData(aggDataCol, 'Settled (multi-yr)', totalGroupingDataAgg.totalGroup)
        });
        totalGroupingDataAgg.totalGroup['_children'] = arbData.slice(0);

        let totalGroupingData = [totalGroupingDataAgg.totalGroup];

        let arbGroupingData = {};
        arbData.filter(arbCase => arbCase.Arbitrators?.length > 0)
            .flatMap(arbCase => arbCase.Arbitrators.map(arbitrator => Object.assign(arbitrator, arbCase)))
            .forEach(arbCase => groupDataByCol('ArbitratorName', arbGroupingData, arbCase));

        Object.values(arbGroupingData).forEach(arbGroup => {
            aggDataCols.forEach(aggDataCol => {
                arbGroup[aggDataCol] = formatAggData(aggDataCol, 'Player Win', arbGroup)
                    + formatAggData(aggDataCol, 'Team Win', arbGroup)
                    + formatAggData(aggDataCol, 'Settled (1yr)', arbGroup)
                    + formatAggData(aggDataCol, 'total', arbGroup)
                    + formatAggData(aggDataCol, 'Settled (multi-yr)', arbGroup)
            })
        })

        let seasonGroupingData = {};
        arbData.forEach(arbCase => groupDataByCol('Season', seasonGroupingData, arbCase));

        Object.values(seasonGroupingData).forEach(arbGroup => {
            aggDataCols.forEach(aggDataCol => {
                arbGroup[aggDataCol] = formatAggData(aggDataCol, 'Player Win', arbGroup)
                    + formatAggData(aggDataCol, 'Team Win', arbGroup)
                    + formatAggData(aggDataCol, 'Settled (1yr)', arbGroup)
                    + formatAggData(aggDataCol, 'total', arbGroup)
                    + formatAggData(aggDataCol, 'Settled (multi-yr)', arbGroup)
            })
        })

        return {
            totalGrouping: totalGroupingData,
            arbGrouping: Object.values(arbGroupingData),
            seasonGrouping : Object.values(seasonGroupingData),
        };

        function formatAggData(colName, summaryType, arbGroupData) {
            if ((arbGroupData.SummaryInfo[summaryType].ArbCaseResult ?? 0) < 1)
                return '';

            let labelMapping = {
                'Player Win': 'Player: ',
                'Team Win': 'Team: ',
                'Settled (1yr)': 'Settled(1yr): ',
                'Settled (multi-yr)': 'Settled(multi-yr): ',
                total: 'Total: ',
            }
            return (summaryType !== 'Player Win' ? '\n' : '')
                + (colName === 'ArbCaseResult' ? labelMapping[summaryType] : '')
                + arbGroupData.SummaryInfo[summaryType][colName];
        }

        function groupDataByCol(colName, mapObj, arbCase) {
            if (!Object.hasOwn(mapObj, arbCase[colName].toString()))
                mapObj[arbCase[colName].toString()] = {
                    SummaryInfo: {
                        'Player Win': {},
                        'Team Win': {},
                        'Settled (1yr)': {},
                        'Settled (multi-yr)': {},
                        total: {},
                    },
                    '_children': [],
                }

            mapObj[arbCase[colName].toString()]['_children'].push(arbCase);
            mapObj[arbCase[colName].toString()][colName] = arbCase[colName]

            mapObj[arbCase[colName].toString()].SummaryInfo[arbCase.ArbCaseResult] = dataAccumulator(
                mapObj[arbCase[colName].toString()].SummaryInfo[arbCase.ArbCaseResult],
                arbCase,
            );
            if (arbCase.ArbCaseResult !== 'Settled (multi-yr)') {
                mapObj[arbCase[colName].toString()].SummaryInfo.total = dataAccumulator(
                    mapObj[arbCase[colName].toString()].SummaryInfo.total,
                    arbCase,
                );
            }

            mapObj[arbCase[colName].toString()] = Object.assign(
                mapObj[arbCase[colName].toString()],
                mapObj[arbCase[colName].toString()].SummaryInfo.total,
            )
            function dataAccumulator(acc, cur) {
                let retData = {};
                retData.ArbCaseResult = (acc.ArbCaseResult ?? 0) + 1;
                retData.aWAR = (acc.aWAR ?? 0) + (cur.aWAR ?? 0);
                retData.ArbPlayerSubmission = (acc.ArbPlayerSubmission ?? 0) + cur.ArbPlayerSubmission;
                retData.ArbMidPoint = (acc.ArbMidPoint ?? 0) + cur.ArbMidPoint;
                retData.ArbTeamSubmission = (acc.ArbTeamSubmission ?? 0) + cur.ArbTeamSubmission;

                switch (cur.ArbCaseResult) {
                    case 'Player Win':
                        retData.ArbSpread = (acc.ArbSpread ?? 0) + cur.ArbSpread;
                        break;
                    case 'Team Win':
                        retData.ArbSpread = (acc.ArbSpread ?? 0) + -1 * cur.ArbSpread;
                        break;
                    case 'Settled (1yr)':
                        if ((cur.ArbMidPoint ?? 0) !== 0)
                            retData.ArbSpread = (acc.ArbSpread ?? 0) + (cur.ArbFinalAmount - cur.ArbMidPoint);
                        break;
                    default:
                        retData.ArbSpread = (acc.ArbSpread ?? 0);
                        break;
                }
                retData.ArbFinalAmount = (acc.ArbFinalAmount ?? 0) + cur.ArbFinalAmount;
                cur.MoneyPeraWAR = cur.ArbFinalAmount / cur.aWAR;
                retData.MoneyPeraWAR = retData.ArbFinalAmount / retData.aWAR;

                return retData;
            }
        }
    }

    function setFilter(filterIndex, type, value) {
        let newFilters = filters.slice();
        let filterToUpdate = newFilters.find(filter => filter.index === filterIndex)
        filterToUpdate[type] = value;
        setFilters(newFilters);
    }

    function updateNotes(filters) {
        let notes = {
            formNotes: [],
        };
        notes.formNotes.push(`Filing Season From: ${startSeason} To: ${endSeason}`);

        if (filters) {
            filters.forEach(filter => {
                if (filter.value) {
                    let filterNote = convertFilterToNote(filter);
                    if (filterNote)
                        notes.formNotes.push(filterNote);
                }
            });
        }

        setExcelNotes(notes);
    }

    let seasonOptions = [];
    for (let i = curSeason; i >= 2002; i--) {
        seasonOptions.push(
            <option key={i} value={i}>{i}</option>
        )
    };
    return (
        <div className='body arbitration-report pdfSection'>
            <div className='pdfChunk'>
                <h2>Arbitration Viewer</h2>
                <br />
                <Row>
                    <Form.Label style={{ fontWeight: 700, marginBottom: 0, paddingRight: '10px' }}>
                        Filing Season From: 
                    </Form.Label>
                    <Form.Control
                        as='select'
                        defaultValue={startSeason}
                        onChange={(event) => {
                            setStartSeason(event.target.value);
                        }}
                        style={{ width: '100px' }}
                    >
                        {seasonOptions}
                    </Form.Control>
                    <Form.Label style={{ fontWeight: 700, marginBottom: 0, paddingRight: '10px' }}>
                        Filing Season To:
                    </Form.Label>
                    <Form.Control
                        as='select'
                        defaultValue={endSeason}
                        onChange={(event) => {
                            setEndSeason(event.target.value);
                        }}
                        style={{ width: '100px' }}
                    >
                        {seasonOptions}
                    </Form.Control>
                </Row>
                <br />
                <h5>Who's on this report</h5>
                <div className='advanced-filter'>
                    {
                        filters.map((filter, filterIndex) =>
                            <StatFilter
                                filter={filter}
                                key={filterIndex}
                                people={filter.people}
                                setFilter={setFilter}
                            />
                        )
                    }
                </div>
                <br />
                <Row className='pdfIgnore'>
                    <Button variant='success'
                        onClick={getData}
                    >
                        Submit
                    </Button>
                    {
                        data?.rows &&
                        <>
                            <div style={{ margin: '0px 0px 0px auto' }}>
                                <ExcelExporter
                                    columns={columns}
                                    data={data.rows}
                                    notes={excelNotes}
                                    reportName={'ArbitrationReport'}
                                />
                                <PDFExporter fileName='ArbitrationReport' />
                            </div>
                        </>
                    }
                    <b className='label'>Summarize By: </b>
                    <Form>
                        <div key={'custom-inline-radio'}>
                            <Form.Check
                                custom
                                inline
                                name='reportType'
                                label='All'
                                type='radio'
                                id='custom-inline-radio-all'
                                defaultChecked
                                onChange={(event) => {
                                    if (event.target.value) {
                                        setReportType('all')
                                    }
                                }}
                            />
                            <Form.Check
                                custom
                                inline
                                name='reportType'
                                label='Arbitrators'
                                type='radio'
                                id='custom-inline-radio-arbitrator'
                                onChange={(event) => {
                                    if (event.target.value) {
                                        setReportType('arbitrator')
                                    }
                                }}
                            />
                            <Form.Check
                                custom
                                inline
                                name='reportType'
                                label='Seasons'
                                type='radio'
                                id='custom-inline-radio-season'
                                onChange={(event) => {
                                    if (event.target.value) {
                                        setReportType('season')
                                    }
                                }}
                            />
                        </div>
                    </Form>
                </Row>
                <br />
            </div>
            {
                data?.loading &&
                <div>
                    <br />
                    <br />
                    <i className='fa fa-spinner fa-spin loading-icon'></i>
                    <br />
                </div>
            }
            {
                data?.arbGrouping && columns && reportType === 'arbitrator' &&
                <div
                    className='pdfChunk tableChunk'
                    style={{
                        width: (columns.reduce((acc, cur) => acc + ((cur.width) > 20 ? (cur.width) : 20), 0)) + 144
                    }}
                >
                    <TabulatorTable
                            cols={[{
                                cssClass: 'leftAlign',
                                field: 'ArbitratorName',
                                headerSort: true,
                                headerSortStartingDir: 'asc',
                                resizable: false,
                                hozAlign: 'left',
                                title: 'Arbitrator',
                                width: 140
                            }].concat(columns)}
                        data={data?.arbGrouping}
                        initSort={[{
                            column: 'ArbitratorName',
                            dir: 'asc'
                        }]}
                        dataTreeElementColumn='ArbitratorName'
                    />
                    <br />
                </div>
            }
            {
                data?.seasonGrouping && columns && reportType === 'season' &&
                <div
                    className='pdfChunk tableChunk'
                    style={{
                        width: (columns.reduce((acc, cur) => acc + ((cur.width) > 20 ? (cur.width) : 20), 0)) + 4
                    }}
                >
                    <TabulatorTable
                        cols={columns}
                        data={data?.seasonGrouping}
                        initSort={[{
                            column: 'Season',
                            dir: 'asc'
                        }]}
                        dataTreeElementColumn='Season'
                    />
                    <br />
                </div>
            }
            {
                data?.totalGrouping && columns && reportType === 'all' &&
                <div
                    className='pdfChunk tableChunk'
                    style={{
                        width: (columns.reduce((acc, cur) => acc + ((cur.width) > 20 ? (cur.width) : 20), 0)) + 4
                    }}
                >
                    <TabulatorTable
                        cols={columns}
                        data={data?.totalGrouping}
                        initSort={[{
                            column: columns?.find(col => col.title === 'Player').field,
                            dir: 'asc'
                        }]}
                        dataTreeElementColumn='Season'
                        dataTreeStartExpanded={true}
                    />
                    <br />
                </div>
            }
        </div>
    )
}

export default ArbitrationReport;